import * as React from 'react'
import * as styles from '../../../assets/styles.module.scss'
import { default as Header } from '../../common/header'
import { default as Heroshot } from '../../common/heroshot'
import { default as Footer } from '../../common/footer'
import { default as LeadCaptureForm } from './LeadCaptureForm'

interface PageProps {}

export default class Page extends React.Component<PageProps, {}> {

    constructor(props: PageProps) {
        super(props)
    }

    public render() {
        return (
            <section className={styles.Container}>
                <Header
                    title="White Paper: Experience before Products: Our approach to Product Design | Juvasoft"
                    link="https://juvasoft.com/resources/white-papers/product-design-process-guide" />
                <Heroshot title={`Experience before Products: Our approach to Product Design`}
                subtitle={`White Paper: In this new guide, you’ll discover tips that can make all the 
                difference in your product design process.`} />
                <div className={styles.Landing}>
                    <div className={styles.Content}>
                        <p>
                            Over the past decade, we’ve designed a user-centric process to develop 
                            digital products that focus entirely on the user experience. In this 
                            new paper, Juvasoft shares its approach of a system of thinking to 
                            build digital products. Now, we know not all products are the 
                            same, however, when you implement our tested and proven 
                            practices, plus an aggressive goal setting, you get to increase 
                            your chances to success.
                        </p>

                        <p>
                            In this new guide, you’ll discover tips that can make all the 
                            difference in your product design process, including:
                        </p>

                        <ul>
                            <li key={1}>Why it’s crucial to start by the user experience and work backwards to the technology.</li>
                            <li key={2}>
                                Understanding why you should understand the problem and its 
                                possible solutions before writing a line of code.
                            </li>
                            <li key={3}>How to define (and understand) your target market and its user persona/s</li>
                            <li key={4}>
                                Understanding if your solution is working (or not) early on the 
                                process, saves you tons of time and money.
                            </li>
                            <li key={5}>How iterating and involving your customer on the Product Development phase pays off.</li>
                            <li key={6}>
                                Most importantly: While, software development is more risk management than 
                                construction or craftsmanship, implementing a process can save your project.
                            </li>
                        </ul>

                        <LeadCaptureForm campaign="Product Design Process" />
                    </div>
                </div>
                <Footer />
            </section>
        )
    }
}